import * as THREE from 'three'

export default class CrossroadsSection
{
    constructor(_options)
    {
        // Options
        this.time = _options.time
        this.resources = _options.resources
        this.objects = _options.objects
        this.areas = _options.areas
        this.tiles = _options.tiles
        this.debug = _options.debug
        this.x = _options.x
        this.y = _options.y

        // Set up
        this.container = new THREE.Object3D()
        this.container.matrixAutoUpdate = false

        this.setStatic()
        this.setTiles()
    }

    setStatic()
    {
        this.objects.add({
            base: this.resources.items.crossroadsStaticBase.scene,
            collision: this.resources.items.crossroadsStaticCollision.scene,
            //floorShadowTexture: this.resources.items.crossroadsStaticFloorShadowTexture,
            offset: new THREE.Vector3(this.x, this.y, 0),
            mass: 0
        })
    }

    setTiles()
    {
        // To intro
        this.tiles.add({
            start: new THREE.Vector2(0, -16.7),
            delta: new THREE.Vector2(0, 7.5)
        })

        //Rounadabout
        this.tiles.add({
            start: new THREE.Vector2(this.x + 12*Math.sin(0*Math.PI/15), this.y + 12*Math.cos(0*Math.PI/15)),
            delta: new THREE.Vector2(1.55*Math.cos(0*Math.PI/15), 1.55*Math.sin(0*Math.PI/15))
        })

        this.tiles.add({
            start: new THREE.Vector2(this.x + 12*Math.sin(1*Math.PI/15), this.y + 12*Math.cos(1*Math.PI/15)),
            delta: new THREE.Vector2(1.55*Math.cos(-1*Math.PI/15), 1.55*Math.sin(-1*Math.PI/15))
        })

        this.tiles.add({
            start: new THREE.Vector2(this.x + 12*Math.sin(2*Math.PI/15), this.y + 12*Math.cos(2*Math.PI/15)),
            delta: new THREE.Vector2(1.55*Math.cos(-2*Math.PI/15), 1.55*Math.sin(-2*Math.PI/15))
        })

        this.tiles.add({
            start: new THREE.Vector2(this.x + 12*Math.sin(3*Math.PI/15), this.y + 12*Math.cos(3*Math.PI/15)),
            delta: new THREE.Vector2(1.55*Math.cos(-3*Math.PI/15), 1.55*Math.sin(-3*Math.PI/15))
        })

        this.tiles.add({
            start: new THREE.Vector2(this.x + 12*Math.sin(4*Math.PI/15), this.y + 12*Math.cos(4*Math.PI/15)),
            delta: new THREE.Vector2(1.55*Math.cos(-4*Math.PI/15), 1.55*Math.sin(-4*Math.PI/15))
        })

        this.tiles.add({
            start: new THREE.Vector2(this.x + 12*Math.sin(5*Math.PI/15), this.y + 12*Math.cos(5*Math.PI/15)),
            delta: new THREE.Vector2(1.55*Math.cos(-5*Math.PI/15), 1.55*Math.sin(-5*Math.PI/15))
        })

        this.tiles.add({
            start: new THREE.Vector2(this.x + 12*Math.sin(6*Math.PI/15), this.y + 12*Math.cos(6*Math.PI/15)),
            delta: new THREE.Vector2(1.55*Math.cos(-6*Math.PI/15), 1.55*Math.sin(-6*Math.PI/15))
        })

        this.tiles.add({
            start: new THREE.Vector2(this.x + 12*Math.sin(7*Math.PI/15), this.y + 12*Math.cos(7*Math.PI/15)),
            delta: new THREE.Vector2(1.55*Math.cos(-7*Math.PI/15), 1.55*Math.sin(-7*Math.PI/15))
        })

        this.tiles.add({
            start: new THREE.Vector2(this.x + 12*Math.sin(8*Math.PI/15), this.y + 12*Math.cos(8*Math.PI/15)),
            delta: new THREE.Vector2(1.55*Math.cos(-8*Math.PI/15), 1.55*Math.sin(-8*Math.PI/15))
        })

        this.tiles.add({
            start: new THREE.Vector2(this.x + 12*Math.sin(9*Math.PI/15), this.y + 12*Math.cos(9*Math.PI/15)),
            delta: new THREE.Vector2(1.55*Math.cos(-9*Math.PI/15), 1.55*Math.sin(-9*Math.PI/15))
        })

        this.tiles.add({
            start: new THREE.Vector2(this.x + 12*Math.sin(10*Math.PI/15), this.y + 12*Math.cos(10*Math.PI/15)),
            delta: new THREE.Vector2(1.55*Math.cos(-10*Math.PI/15), 1.55*Math.sin(-10*Math.PI/15))
        })

        this.tiles.add({
            start: new THREE.Vector2(this.x + 12*Math.sin(11*Math.PI/15), this.y + 12*Math.cos(11*Math.PI/15)),
            delta: new THREE.Vector2(1.55*Math.cos(-11*Math.PI/15), 1.55*Math.sin(-11*Math.PI/15))
        })

        this.tiles.add({
            start: new THREE.Vector2(this.x + 12*Math.sin(12*Math.PI/15), this.y + 12*Math.cos(12*Math.PI/15)),
            delta: new THREE.Vector2(1.55*Math.cos(-12*Math.PI/15), 1.55*Math.sin(-12*Math.PI/15))
        })

        this.tiles.add({
            start: new THREE.Vector2(this.x + 12*Math.sin(13*Math.PI/15), this.y + 12*Math.cos(13*Math.PI/15)),
            delta: new THREE.Vector2(1.55*Math.cos(-13*Math.PI/15), 1.55*Math.sin(-13*Math.PI/15))
        })

        this.tiles.add({
            start: new THREE.Vector2(this.x + 12*Math.sin(14*Math.PI/15), this.y + 12*Math.cos(14*Math.PI/15)),
            delta: new THREE.Vector2(1.55*Math.cos(-14*Math.PI/15), 1.55*Math.sin(-14*Math.PI/15))
        })

        this.tiles.add({
            start: new THREE.Vector2(this.x + 12*Math.sin(15*Math.PI/15), this.y + 12*Math.cos(15*Math.PI/15)),
            delta: new THREE.Vector2(1.55*Math.cos(-15*Math.PI/15), 1.55*Math.sin(-15*Math.PI/15))
        })

        this.tiles.add({
            start: new THREE.Vector2(this.x + 12*Math.sin(16*Math.PI/15), this.y + 12*Math.cos(16*Math.PI/15)),
            delta: new THREE.Vector2(1.55*Math.cos(14*Math.PI/15), 1.55*Math.sin(14*Math.PI/15))
        })

        this.tiles.add({
            start: new THREE.Vector2(this.x + 12*Math.sin(17*Math.PI/15), this.y + 12*Math.cos(17*Math.PI/15)),
            delta: new THREE.Vector2(1.55*Math.cos(13*Math.PI/15), 1.55*Math.sin(13*Math.PI/15))
        })

        this.tiles.add({
            start: new THREE.Vector2(this.x + 12*Math.sin(18*Math.PI/15), this.y + 12*Math.cos(18*Math.PI/15)),
            delta: new THREE.Vector2(1.55*Math.cos(12*Math.PI/15), 1.55*Math.sin(12*Math.PI/15))
        })

        this.tiles.add({
            start: new THREE.Vector2(this.x + 12*Math.sin(19*Math.PI/15), this.y + 12*Math.cos(19*Math.PI/15)),
            delta: new THREE.Vector2(1.55*Math.cos(11*Math.PI/15), 1.55*Math.sin(11*Math.PI/15))
        })

        this.tiles.add({
            start: new THREE.Vector2(this.x + 12*Math.sin(20*Math.PI/15), this.y + 12*Math.cos(20*Math.PI/15)),
            delta: new THREE.Vector2(1.55*Math.cos(10*Math.PI/15), 1.55*Math.sin(10*Math.PI/15))
        })

        this.tiles.add({
            start: new THREE.Vector2(this.x + 12*Math.sin(21*Math.PI/15), this.y + 12*Math.cos(21*Math.PI/15)),
            delta: new THREE.Vector2(1.55*Math.cos(9*Math.PI/15), 1.55*Math.sin(9*Math.PI/15))
        })

        this.tiles.add({
            start: new THREE.Vector2(this.x + 12*Math.sin(22*Math.PI/15), this.y + 12*Math.cos(22*Math.PI/15)),
            delta: new THREE.Vector2(1.55*Math.cos(8*Math.PI/15), 1.55*Math.sin(8*Math.PI/15))
        })

        this.tiles.add({
            start: new THREE.Vector2(this.x + 12*Math.sin(23*Math.PI/15), this.y + 12*Math.cos(23*Math.PI/15)),
            delta: new THREE.Vector2(1.55*Math.cos(7*Math.PI/15), 1.55*Math.sin(7*Math.PI/15))
        })

        this.tiles.add({
            start: new THREE.Vector2(this.x + 12*Math.sin(24*Math.PI/15), this.y + 12*Math.cos(24*Math.PI/15)),
            delta: new THREE.Vector2(1.55*Math.cos(6*Math.PI/15), 1.55*Math.sin(6*Math.PI/15))
        })

        this.tiles.add({
            start: new THREE.Vector2(this.x + 12*Math.sin(25*Math.PI/15), this.y + 12*Math.cos(25*Math.PI/15)),
            delta: new THREE.Vector2(1.55*Math.cos(5*Math.PI/15), 1.55*Math.sin(5*Math.PI/15))
        })

        this.tiles.add({
            start: new THREE.Vector2(this.x + 12*Math.sin(26*Math.PI/15), this.y + 12*Math.cos(26*Math.PI/15)),
            delta: new THREE.Vector2(1.55*Math.cos(4*Math.PI/15), 1.55*Math.sin(4*Math.PI/15))
        })

        this.tiles.add({
            start: new THREE.Vector2(this.x + 12*Math.sin(27*Math.PI/15), this.y + 12*Math.cos(27*Math.PI/15)),
            delta: new THREE.Vector2(1.55*Math.cos(3*Math.PI/15), 1.55*Math.sin(3*Math.PI/15))
        })

        this.tiles.add({
            start: new THREE.Vector2(this.x + 12*Math.sin(28*Math.PI/15), this.y + 12*Math.cos(28*Math.PI/15)),
            delta: new THREE.Vector2(1.55*Math.cos(2*Math.PI/15), 1.55*Math.sin(2*Math.PI/15))
        })

        this.tiles.add({
            start: new THREE.Vector2(this.x + 12*Math.sin(29*Math.PI/15), this.y + 12*Math.cos(29*Math.PI/15)),
            delta: new THREE.Vector2(1.55*Math.cos(1*Math.PI/15), 1.55*Math.sin(1*Math.PI/15))
        })

        // To projects
        this.tiles.add({
            start: new THREE.Vector2(13.7, -30),
            delta: new THREE.Vector2(7.5, 0)
        })

        // To playground
        this.tiles.add({
            start: new THREE.Vector2(-13.7, -30),
            delta: new THREE.Vector2(-7.5, 0)
        })
        
    }
}