import * as THREE from 'three'
import EventEmitter from './Utils/EventEmitter.js'
import Loader from './Utils/Loader.js'

// Matcaps
import matcapBeigeSource from '/models/matcaps/beige.png'
import matcapBlackSource from '/models/matcaps/black.png'
import matcapOrangeSource from '/models/matcaps/orange.png'
import matcapRedSource from '/models/matcaps/red.png'
import matcapWhiteSource from '/models/matcaps/white.png'
import matcapGreenSource from '/models/matcaps/green.png'
import matcapBrownSource from '/models/matcaps/brown.png'
import matcapGraySource from '/models/matcaps/gray.png'
import matcapEmeraldGreenSource from '/models/matcaps/emeraldGreen.png'
import matcapPurpleSource from '/models/matcaps/purple.png'
import matcapBlueSource from '/models/matcaps/blue.png'
import matcapYellowSource from '/models/matcaps/yellow.png'
import matcapMetalSource from '/models/matcaps/metal.png'
// import matcapGoldSource from '/models/matcaps/gold.png'

// Area
import areaKeyEnterSource from '/models/area/keyEnter.png'
import areaEnterSource from '/models/area/enter.png'
import areaOpenSource from '/models/area/open.png'
import areaResetSource from '/models/area/reset.png'
import areaQuestionMarkSource from '/models/area/questionMark.png'


export default class Resources extends EventEmitter
{
    constructor()
    {
        super()

        this.loader = new Loader()
        this.items = {}

        this.loader.load([
            // Matcaps
            { name: 'matcapBeige', source: matcapBeigeSource, type: 'texture' },
            { name: 'matcapBlack', source: matcapBlackSource, type: 'texture' },
            { name: 'matcapOrange', source: matcapOrangeSource, type: 'texture' },
            { name: 'matcapRed', source: matcapRedSource, type: 'texture' },
            { name: 'matcapWhite', source: matcapWhiteSource, type: 'texture' },
            { name: 'matcapGreen', source: matcapGreenSource, type: 'texture' },
            { name: 'matcapBrown', source: matcapBrownSource, type: 'texture' },
            { name: 'matcapGray', source: matcapGraySource, type: 'texture' },
            { name: 'matcapEmeraldGreen', source: matcapEmeraldGreenSource, type: 'texture' },
            { name: 'matcapPurple', source: matcapPurpleSource, type: 'texture' },
            { name: 'matcapBlue', source: matcapBlueSource, type: 'texture' },
            { name: 'matcapYellow', source: matcapYellowSource, type: 'texture' },
            { name: 'matcapMetal', source: matcapMetalSource, type: 'texture' },
            // { name: 'matcapGold', source: matcapGoldSource, type: 'texture' },

            // Intro
            { name: 'introStaticBase', source: '/models/intro/static/base.glb' },
            { name: 'introStaticCollision', source: '/models/intro/static/collision.glb' },
            { name: 'introStaticFloorShadow', source: '/models/intro/static/floorShadow.png', type: 'texture' },

            { name: 'introInstructionsLabels', source: '/models/intro/instructions/labels.glb' },
            { name: 'introInstructionsArrows', source: '/models/intro/instructions/arrows.png', type: 'texture' },
            { name: 'introInstructionsControls', source: '/models/intro/instructions/controls.png', type: 'texture' },
            { name: 'introInstructionsOther', source: '/models/intro/instructions/other.png', type: 'texture' },

            { name: 'introArrowKeyBase', source: '/models/intro/arrowKey/base.glb' },
            { name: 'introArrowKeyCollision', source: '/models/intro/arrowKey/collision.glb' },

            { name: 'introJBase', source: '/models/intro/j/base.glb' },
            { name: 'introJCollision', source: '/models/intro/j/collision.glb' },

            { name: 'introI1Base', source: '/models/intro/i/base1.glb' },
            { name: 'introI1Collision', source: '/models/intro/i/collision1.glb' },
            
            { name: 'introA1Base', source: '/models/intro/a/base1.glb' },
            { name: 'introA1Collision', source: '/models/intro/a/collision1.glb' },

            { name: 'introN1Base', source: '/models/intro/n/base1.glb' },
            { name: 'introN1Collision', source: '/models/intro/n/collision1.glb' },

            { name: 'introN2Base', source: '/models/intro/n/base2.glb' },
            { name: 'introN2Collision', source: '/models/intro/n/collision2.glb' },

            { name: 'introI2Base', source: '/models/intro/i/base2.glb' },
            { name: 'introI2Collision', source: '/models/intro/i/collision2.glb' },

            { name: 'introN3Base', source: '/models/intro/n/base3.glb' },
            { name: 'introN3Collision', source: '/models/intro/n/collision3.glb' },

            { name: 'introG1Base', source: '/models/intro/g/base1.glb' },
            { name: 'introG1Collision', source: '/models/intro/g/collision1.glb' },

            { name: 'introZBase', source: '/models/intro/z/base.glb' },
            { name: 'introZCollision', source: '/models/intro/z/collision.glb' },

            { name: 'introHBase', source: '/models/intro/h/base.glb' },
            { name: 'introHCollision', source: '/models/intro/h/collision.glb' },

            { name: 'introUBase', source: '/models/intro/u/base.glb' },
            { name: 'introUCollision', source: '/models/intro/u/collision.glb' },

            { name: 'introA2Base', source: '/models/intro/a/base2.glb' },
            { name: 'introA2Collision', source: '/models/intro/a/collision2.glb' },

            { name: 'introN4Base', source: '/models/intro/n/base4.glb' },
            { name: 'introN4Collision', source: '/models/intro/n/collision4.glb' },

            { name: 'introG2Base', source: '/models/intro/g/base2.glb' },
            { name: 'introG2Collision', source: '/models/intro/g/collision2.glb' },

            { name: 'introComputerBase', source: '/models/intro/computer/base.glb' },
            { name: 'introComputerCollision', source: '/models/intro/computer/collision.glb' },

            { name: 'introEngineerBase', source: '/models/intro/engineer/base.glb' },
            { name: 'introEngineerCollision', source: '/models/intro/engineer/collision.glb' },

            // Brick
            { name: 'brickBase', source: '/models/brick/base.glb' },
            { name: 'brickCollision', source: '/models/brick/collision.glb' },
            
            // Horn
            { name: 'hornBase', source: '/models/horn/base.glb' },
            { name: 'hornCollision', source: '/models/horn/collision.glb' },

            //Crossroads
            { name: 'crossroadsStaticBase', source: '/models/crossroads/base.glb' },
            { name: 'crossroadsStaticCollision', source: '/models/crossroads/collision.glb' },

            // Project
            { name: 'projectsBoardStructure', source: '/models/projects/board/structure.glb' },
            { name: 'projectsBoardCollision', source: '/models/projects/board/collision.glb' },
            { name: 'projectsBoardStructureFloorShadow', source: '/models/projects/board/floorShadow.png', type: 'texture' },
            { name: 'projectsBoardPlane', source: '/models/projects/board/plane.glb' },

            { name: 'projectsHomepageFloor', source: '/models/projects/homepage/floorTexture.png', type: 'texture' },
            { name: 'projectsCEGFloor', source: '/models/projects/ceg/floorTexture.png', type: 'texture' },
            { name: 'projectsTanksFloor', source: '/models/projects/tanks/floorTexture.png', type: 'texture' },

            // Information
            { name: 'informationStaticBase', source: '/models/information/base.glb' },
            { name: 'informationStaticCollision', source: '/models/information/collision.glb' },

            { name: 'informationContactInstagramLabel', source: '/models/information/contactInstagramLabel.png', type: 'texture' },
            { name: 'informationContactGithubLabel', source: '/models/information/contactGithubLabel.png', type: 'texture' },
            { name: 'informationContactLinkedinLabel', source: '/models/information/contactLinkedinLabel.png', type: 'texture' },
            { name: 'informationContactMailLabel', source: '/models/information/contactMailLabel.png', type: 'texture' },

            { name: 'informationActivities', source: '/models/information/activities.png', type: 'texture' },

            // Playground
            { name: 'playgroundStaticBase', source: '/models/playground/base.glb' },
            { name: 'playgroundStaticCollision', source: '/models/playground/collision.glb' },
            { name: 'playgroundStaticFloorShadow', source: '/models/playground/floorShadow.png', type: 'texture' },

            // Areas
            { name: 'areaKeyEnter', source: areaKeyEnterSource, type: 'texture' },
            { name: 'areaEnter', source: areaEnterSource, type: 'texture' },
            { name: 'areaOpen', source: areaOpenSource, type: 'texture' },
            { name: 'areaReset', source: areaResetSource, type: 'texture' },
            { name: 'areaQuestionMark', source: areaQuestionMarkSource, type: 'texture' },

            //AEV
            {name: 'aevModel', source: '/models/AEV/aev.glb'},

            //Skateoard
            {name: 'skateboardModel', source: '/models/skateboard/skateboard.glb'},

            // Car default
            { name: 'carDefaultChassis', source: '/models/car/default/chassis.glb' },
            { name: 'carDefaultWheel', source: '/models/car/default/wheel.glb' },
            { name: 'carDefaultBackLightsBrake', source: '/models/car/default/backLightsBrake.glb' },
            { name: 'carDefaultBackLightsReverse', source: '/models/car/default/backLightsReverse.glb' },
            { name: 'carDefaultAntena', source: '/models/car/default/antena.glb' },

            // Tiles
            { name: 'tilesABase', source: '/models/tiles/a/base.glb' },
            { name: 'tilesACollision', source: '/models/tiles/a/collision.glb' },

            { name: 'tilesBBase', source: '/models/tiles/b/base.glb' },
            { name: 'tilesBCollision', source: '/models/tiles/b/collision.glb' },

            { name: 'tilesCBase', source: '/models/tiles/c/base.glb' },
            { name: 'tilesCCollision', source: '/models/tiles/c/collision.glb' },

            { name: 'tilesDBase', source: '/models/tiles/d/base.glb' },
            { name: 'tilesDCollision', source: '/models/tiles/d/collision.glb' },

            { name: 'tilesEBase', source: '/models/tiles/e/base.glb' },
            { name: 'tilesECollision', source: '/models/tiles/e/collision.glb' },

            // Bowling ball
            { name: 'bowlingBallBase', source: '/models/bowlingBall/base.glb' },
            { name: 'bowlingBallCollision', source: '/models/bowlingBall/collision.glb'},

            // Bowling Pin
            { name: 'bowlingPinBase', source: '/models/bowlingPin/base.glb' },
            { name: 'bowlingPinCollision', source: '/models/bowlingPin/collision.glb' },

            // Cannon ball
            { name: 'cannonBallBase', source: '/models/cannonBall/base.glb' },
            { name: 'cannonBallCollision', source: '/models/cannonBall/collision.glb'},

        ])

        this.loader.on('fileEnd', (_resource, _data) =>
        {
            this.items[_resource.name] = _data

            // Texture
            if(_resource.type === 'texture')
            {
                const texture = new THREE.Texture(_data)
                texture.needsUpdate = true

                this.items[`${_resource.name}Texture`] = texture
            }

            // Trigger progress
            //console.log(this.loader.loaded / this.loader.toLoad)
            this.trigger('progress', [this.loader.loaded / this.loader.toLoad])
        })

        this.loader.on('end', () =>
        {
            // Trigger ready
            console.log(this.items)
            this.trigger('ready')
        })
    }
}