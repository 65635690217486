import CANNON from 'cannon' 
import * as THREE from 'three'

import engineSound from '/sounds/engines/1/low_off.mp3'
import skateboardEngineSound from '/sounds/skateboard/skateboardEngine.mp3'
import celloEngineSound from '/sounds/cello/celloEngine.mp3'

export default class Car
{
    constructor(_options)
    {
        // Options
        this.time = _options.time
        this.resources = _options.resources
        this.objects = _options.objects
        this.physics = _options.physics
        this.shadows = _options.shadows
        this.materials = _options.materials
        this.controls = _options.controls
        this.sounds = _options.sounds
        this.renderer = _options.renderer
        this.camera = _options.camera
        this.debug = _options.debug
        this.config = _options.config

        // Set up
        this.container = new THREE.Object3D()
        this.position = new THREE.Vector3()

        // Debug
        if(this.debug)
        {
            this.debugFolder = this.debug.addFolder('car')
            // this.debugFolder.close()
        }

        this.setModels()
        this.setMovement()
        this.setChassis()
        this.setAntena()
        this.setBackLights()
        this.setWheels()
        this.setShootingBall()
        this.setKlaxon()

        /**
         * Actions
         */
        this.controls.on('action', (_name) =>
        {
            switch(_name)
            {
                case 'reset':
                    let idx = 0
                    while (this.chassis.object.children.length !== 3)
                    {
                        const child = this.chassis.object.children[idx]
        
                        if (child === this.antena.object || child === this.backLightsBrake.object || child === this.backLightsReverse.object)
                        {
                            idx += 1
                        }
                        else
                        {
                            child.traverse(function (c) {
                                if (c.isMesh) {
                                    c.geometry.dispose()
                                    c.material.dispose()
                                }
                            })
                            this.chassis.object.remove(child)
                        }
                    }
                    
                    if (this.config.aev)
                    {
                        this.config.aev = false
                        this.config.skateboard = true
                        for (let i = 0; i < this.skateboard.object.children.length; i++)
                        {
                            const child = this.skateboard.object.children[i].clone()
                            this.chassis.object.add(child)
                        }
                        this.antena.object.visible = false
                        this.backLightsBrake.object.visible = false
                        this.backLightsReverse.object.visible = false
                        for (const wheel of this.wheels.items)
                        {
                            wheel.visible = false
                        }
                        for(const shadow of this.shadows.items)
                        {
                            if (shadow.reference === this.chassis.object)
                            {
                                shadow.mesh.scale.set(3.3, 1.5, 2.4)
                            }
                        }
                        this.physics.car.options.chassisWidth = 0.65
                        this.physics.car.options.chassisHeight = 1.16
                        this.physics.car.options.chassisDepth = 2.5
                        this.physics.car.options.chassisOffset = new CANNON.Vec3(0, 0, 0.41)
                        this.physics.car.options.chassisMass = 20
                        this.physics.car.options.wheelFrontOffsetDepth = 0.85
                        this.physics.car.options.wheelBackOffsetDepth = - 0.85
                        this.physics.car.options.wheelOffsetWidth = 0.39
                        this.physics.car.options.wheelRadius = 0.25
                        this.physics.car.options.wheelHeight = 0.24
                        this.physics.car.options.wheelSuspensionStiffness = 25
                        this.physics.car.options.wheelSuspensionRestLength = 0.1
                        this.physics.car.options.wheelFrictionSlip = 5
                        this.physics.car.options.wheelDampingRelaxation = 1.8
                        this.physics.car.options.wheelDampingCompression = 1.5
                        this.physics.car.options.wheelMaxSuspensionForce = 100000
                        this.physics.car.options.wheelRollInfluence =  0.01
                        this.physics.car.options.wheelMaxSuspensionTravel = 0.3
                        this.physics.car.options.wheelCustomSlidingRotationalSpeed = - 30
                        this.physics.car.options.wheelMass = 5
                        this.physics.car.options.controlsSteeringSpeed = 0.005
                        this.physics.car.options.controlsSteeringMax = Math.PI * 0.17
                        this.physics.car.options.controlsSteeringQuad = false
                        this.physics.car.options.controlsAcceleratinMaxSpeed = 0.055
                        this.physics.car.options.controlsAcceleratinMaxSpeedBoost = 0.11
                        this.physics.car.options.controlsAcceleratingSpeed = 2
                        this.physics.car.options.controlsAcceleratingSpeedBoost = 3.5
                        this.physics.car.options.controlsAcceleratingQuad = true
                        this.physics.car.options.controlsBrakeStrength = 0.45

                        this.sounds.engine.sound.stop()
                        this.sounds.engine.sound = new Howl({
                            src: [skateboardEngineSound],
                            loop: true
                        })
                        this.sounds.engine.sound.play()

                    }
                    else if (this.config.skateboard)
                    {
                        this.config.skateboard = false
                        this.config.cello = true
                        for (let i = 0; i < this.cello.object.children.length; i++)
                        {
                            const child = this.cello.object.children[i].clone()
                            this.chassis.object.add(child)
                        }
                        this.antena.object.visible = true
                        this.backLightsBrake.object.visible = true
                        this.backLightsReverse.object.visible = true
                        for (const wheel of this.wheels.items)
                        {
                            wheel.visible = true
                        }
                        for(const shadow of this.shadows.items)
                        {
                            if (shadow.reference === this.chassis.object)
                            {
                                shadow.mesh.scale.set(3, 2, 2.4)
                            }
                        }
                        this.physics.car.options.chassisWidth = 1.02
                        this.physics.car.options.chassisHeight = 1.16
                        this.physics.car.options.chassisDepth = 2.03
                        this.physics.car.options.chassisOffset = new CANNON.Vec3(0, 0, 0.41)
                        this.physics.car.options.chassisMass = 20
                        this.physics.car.options.wheelFrontOffsetDepth = 0.635
                        this.physics.car.options.wheelBackOffsetDepth = - 0.635
                        this.physics.car.options.wheelOffsetWidth = 0.39
                        this.physics.car.options.wheelRadius = 0.25
                        this.physics.car.options.wheelHeight = 0.24
                        this.physics.car.options.wheelSuspensionStiffness = 25
                        this.physics.car.options.wheelSuspensionRestLength = 0.1
                        this.physics.car.options.wheelFrictionSlip = 5
                        this.physics.car.options.wheelDampingRelaxation = 1.8
                        this.physics.car.options.wheelDampingCompression = 1.5
                        this.physics.car.options.wheelMaxSuspensionForce = 100000
                        this.physics.car.options.wheelRollInfluence =  0.01
                        this.physics.car.options.wheelMaxSuspensionTravel = 0.3
                        this.physics.car.options.wheelCustomSlidingRotationalSpeed = - 30
                        this.physics.car.options.wheelMass = 5
                        this.physics.car.options.controlsSteeringSpeed = 0.005
                        this.physics.car.options.controlsSteeringMax = Math.PI * 0.17
                        this.physics.car.options.controlsSteeringQuad = false
                        this.physics.car.options.controlsAcceleratinMaxSpeed = 0.055
                        this.physics.car.options.controlsAcceleratinMaxSpeedBoost = 0.11
                        this.physics.car.options.controlsAcceleratingSpeed = 2
                        this.physics.car.options.controlsAcceleratingSpeedBoost = 3.5
                        this.physics.car.options.controlsAcceleratingQuad = true
                        this.physics.car.options.controlsBrakeStrength = 0.45

                        this.sounds.engine.sound.stop()
                        this.sounds.engine.sound = new Howl({
                            src: [celloEngineSound],
                            loop: true
                        })
                        this.sounds.engine.sound.play()

                    }
                    else if (this.config.cello)
                    {
                        this.config.cello = false
                        this.config.aev = true
                        for (let i = 0; i < this.aev.object.children.length; i++)
                        {
                            const child = this.aev.object.children[i].clone()
                            this.chassis.object.add(child)
                        }
                        this.antena.object.visible = false
                        this.backLightsBrake.object.visible = false
                        this.backLightsReverse.object.visible = false
                        for (const wheel of this.wheels.items)
                        {
                            wheel.visible = false
                        }
                        for(const shadow of this.shadows.items)
                        {
                            if (shadow.reference === this.chassis.object)
                            {
                                shadow.mesh.scale.set(7, 3, 2.4)
                            }
                        }

                        this.physics.car.options = {}
                        this.physics.car.options.chassisWidth = 1.8
                        this.physics.car.options.chassisHeight = 1.16
                        this.physics.car.options.chassisDepth = 5.5
                        this.physics.car.options.chassisOffset = new CANNON.Vec3(0, 0, 0.41)
                        this.physics.car.options.chassisMass = 30
                        this.physics.car.options.wheelFrontOffsetDepth = 2
                        this.physics.car.options.wheelBackOffsetDepth = -2
                        this.physics.car.options.wheelOffsetWidth = 0.7
                        this.physics.car.options.wheelRadius = 0.25
                        this.physics.car.options.wheelHeight = 0.24
                        this.physics.car.options.wheelSuspensionStiffness = 40
                        this.physics.car.options.wheelSuspensionRestLength = 0.1
                        this.physics.car.options.wheelFrictionSlip = 5
                        this.physics.car.options.wheelDampingRelaxation = 1.8
                        this.physics.car.options.wheelDampingCompression = 1.5
                        this.physics.car.options.wheelMaxSuspensionForce = 100000
                        this.physics.car.options.wheelRollInfluence =  0.01
                        this.physics.car.options.wheelMaxSuspensionTravel = 0.3
                        this.physics.car.options.wheelCustomSlidingRotationalSpeed = - 30
                        this.physics.car.options.wheelMass = 5
                        this.physics.car.options.controlsSteeringSpeed = 0.005
                        this.physics.car.options.controlsSteeringMax = 1
                        this.physics.car.options.controlsSteeringQuad = false
                        this.physics.car.options.controlsAcceleratinMaxSpeed = 0.055
                        this.physics.car.options.controlsAcceleratinMaxSpeedBoost = 0.11
                        this.physics.car.options.controlsAcceleratingSpeed = 2
                        this.physics.car.options.controlsAcceleratingSpeedBoost = 3.5
                        this.physics.car.options.controlsAcceleratingQuad = true
                        this.physics.car.options.controlsBrakeStrength = 0.45

                        this.sounds.engine.sound.stop()
                        this.sounds.engine.sound = new Howl({
                            src: [engineSound],
                            loop: true
                        })
                        this.sounds.engine.sound.play()
                    }
                    this.physics.car.recreate()
                    break
            }
        })
    }

    setModels()
    {
        this.models = {}

        this.models.chassis = this.resources.items.aevModel

        this.models.antena = this.resources.items.carDefaultAntena
        this.models.backLightsBrake = this.resources.items.carDefaultBackLightsBrake
        this.models.backLightsReverse = this.resources.items.carDefaultBackLightsReverse
        this.models.wheel = this.resources.items.carDefaultWheel
        
        this.aev = {}
        this.aev.object = this.objects.getConvertedMesh(this.resources.items.aevModel.scene.children)

        this.skateboard = {}
        this.skateboard.object = this.objects.getConvertedMesh(this.resources.items.skateboardModel.scene.children)

        this.cello = {}
        this.cello.object = this.objects.getConvertedMesh(this.resources.items.carDefaultChassis.scene.children)
    }

    setMovement()
    {
        this.movement = {}
        this.movement.speed = new THREE.Vector3()
        this.movement.localSpeed = new THREE.Vector3()
        this.movement.acceleration = new THREE.Vector3()
        this.movement.localAcceleration = new THREE.Vector3()

        // Time tick
        this.time.on('tick', () =>
        {
            // Movement
            const movementSpeed = new THREE.Vector3()
            movementSpeed.copy(this.chassis.object.position).sub(this.chassis.oldPosition)
            this.movement.acceleration = movementSpeed.clone().sub(this.movement.speed)
            this.movement.speed.copy(movementSpeed)

            this.movement.localSpeed = this.movement.speed.clone().applyAxisAngle(new THREE.Vector3(0, 0, 1), - this.chassis.object.rotation.z)
            this.movement.localAcceleration = this.movement.acceleration.clone().applyAxisAngle(new THREE.Vector3(0, 0, 1), - this.chassis.object.rotation.z)

            // Sound
            this.sounds.engine.speed = this.movement.localSpeed.x
            this.sounds.engine.acceleration = this.controls.actions.forward ? (this.controls.actions.boost ? 1 : 0.5) : 0

            if(this.movement.localAcceleration.x > 0.01)
            {
                this.sounds.play('screech')
            }
        })
    }

    setChassis()
    {
        this.chassis = {}
        this.chassis.offset = new THREE.Vector3(0, 0, -0.28)
        this.chassis.object = this.objects.getConvertedMesh(this.models.chassis.scene.children)
        for (let i = 0; i < this.aev.object.children.length; i++)
        {
            const child = this.aev.object.children[i].clone()
            this.chassis.object.add(child)
        }
        this.chassis.object.position.copy(this.physics.car.chassis.body.position)
        this.chassis.oldPosition = this.chassis.object.position.clone()
        this.container.add(this.chassis.object)

        this.shadows.add(this.chassis.object, { sizeX: 7, sizeY: 3, offsetZ: 0.2 })

        // Time tick
        this.time.on('tick', () =>
        {
            // Save old position for movement calculation
            this.chassis.oldPosition = this.chassis.object.position.clone()


            this.chassis.object.position.copy(this.physics.car.chassis.body.position).add(this.chassis.offset)
            this.chassis.object.quaternion.copy(this.physics.car.chassis.body.quaternion)
            

            // Update position
            this.position.copy(this.chassis.object.position)
        })
    }

    setAntena()
    {
        this.antena = {}

        this.antena.speedStrength = 10
        this.antena.damping = 0.035
        this.antena.pullBackStrength = 0.02

        this.antena.object = this.objects.getConvertedMesh(this.models.antena.scene.children)
        this.antena.object.visible = false

        this.chassis.object.add(this.antena.object)

        this.antena.speed = new THREE.Vector2()
        this.antena.absolutePosition = new THREE.Vector2()
        this.antena.localPosition = new THREE.Vector2()

        // Time tick
        this.time.on('tick', () =>
        {
            const max = 1
            const accelerationX = Math.min(Math.max(this.movement.acceleration.x, - max), max)
            const accelerationY = Math.min(Math.max(this.movement.acceleration.y, - max), max)

            this.antena.speed.x -= accelerationX * this.antena.speedStrength
            this.antena.speed.y -= accelerationY * this.antena.speedStrength

            const position = this.antena.absolutePosition.clone()
            const pullBack = position.negate().multiplyScalar(position.length() * this.antena.pullBackStrength)
            this.antena.speed.add(pullBack)

            this.antena.speed.x *= 1 - this.antena.damping
            this.antena.speed.y *= 1 - this.antena.damping

            this.antena.absolutePosition.add(this.antena.speed)

            this.antena.localPosition.copy(this.antena.absolutePosition)
            this.antena.localPosition.rotateAround(new THREE.Vector2(), - this.chassis.object.rotation.z)

            this.antena.object.rotation.y = this.antena.localPosition.x * 0.1
            this.antena.object.rotation.x = this.antena.localPosition.y * 0.
        })

        // Debug
        if(this.debug)
        {
            const folder = this.debugFolder.addFolder('antena')
            folder.open()

            folder.add(this.antena, 'speedStrength').step(0.001).min(0).max(50)
            folder.add(this.antena, 'damping').step(0.0001).min(0).max(0.1)
            folder.add(this.antena, 'pullBackStrength').step(0.0001).min(0).max(0.1)
        }
    }

    setBackLights()
    {
        this.backLightsBrake = {}

        this.backLightsBrake.material = this.materials.pures.items.red.clone()
        this.backLightsBrake.material.transparent = true
        this.backLightsBrake.material.opacity = 0.5

        this.backLightsBrake.object = this.objects.getConvertedMesh(this.models.backLightsBrake.scene.children)
        this.backLightsBrake.object.visible = false

        for(const _child of this.backLightsBrake.object.children)
        {
            _child.material = this.backLightsBrake.material
        }

        this.chassis.object.add(this.backLightsBrake.object)

        // Back lights brake
        this.backLightsReverse = {}

        this.backLightsReverse.material = this.materials.pures.items.yellow.clone()
        this.backLightsReverse.material.transparent = true
        this.backLightsReverse.material.opacity = 0.5

        this.backLightsReverse.object = this.objects.getConvertedMesh(this.models.backLightsReverse.scene.children)
        this.backLightsReverse.object.visible = false

        for(const _child of this.backLightsReverse.object.children)
        {
            _child.material = this.backLightsReverse.material
        }

        this.chassis.object.add(this.backLightsReverse.object)

        // Time tick
        this.time.on('tick', () =>
        {
            this.backLightsBrake.material.opacity = this.physics.controls.actions.brake ? 1 : 0.5
            this.backLightsReverse.material.opacity = this.physics.controls.actions.backward ? 1 : 0.5
        })
    }

    setWheels()
    {
        this.wheels = {}
        this.wheels.object = this.objects.getConvertedMesh(this.models.wheel.scene.children)
        this.wheels.object.visible = false

        this.wheels.items = []

        for(let i = 0; i < 4; i++)
        {
            const object = this.wheels.object.clone()
            this.wheels.items.push(object)
            this.container.add(object)
        }

        // Time tick
        this.time.on('tick', () =>
        {

            for(const _wheelKey in this.physics.car.wheels.bodies)
            {
                const wheelBody = this.physics.car.wheels.bodies[_wheelKey]
                const wheelObject = this.wheels.items[_wheelKey]

                wheelObject.position.copy(wheelBody.position)
                wheelObject.quaternion.copy(wheelBody.quaternion)
            }
            
        })
    }


    setShootingBall()
    {
        this.shoot = {}
        this.shoot.waitDuration = 250
        this.shoot.can = true

        window.addEventListener('keydown', (_event) =>
        {
            if(_event.key === ' ' && this.shoot.can && this.config.aev)
            {
                this.shoot.can = false
                window.setTimeout(() =>
                {
                    this.shoot.can = true
                }, this.shoot.waitDuration)

                const x = this.position.x 
                const y = this.position.y 
                const z = this.position.z + 1
                const bowlingBall = this.objects.add({
                    base: this.resources.items.cannonBallBase.scene,
                    collision: this.resources.items.cannonBallCollision.scene,
                    offset: new THREE.Vector3(x, y, z),
                    rotation: new THREE.Euler(Math.PI * 0.5, 0, 0),
                    duplicated: true,
                    mass: 5,
                    soundName: 'bowlingBall',
                    sleep: false
                })

                let direction = new CANNON.Vec3(Math.cos(this.physics.car.angle), Math.sin(this.physics.car.angle), 0)
                direction.normalize()
                direction = direction.scale(100)
                bowlingBall.collision.body.applyImpulse(direction, bowlingBall.collision.body.position)
            }
        })
    }

    setKlaxon()
    {
        this.klaxon = {}
        this.klaxon.waitDuration = 150
        this.klaxon.can = true

        window.addEventListener('keydown', (_event) =>
        {
            // Play horn sound
            if((_event.key === 'h' || _event.key === 'H') && this.klaxon.can)
            {
                this.klaxon.can = false
                window.setTimeout(() =>
                {
                    this.klaxon.can = true
                }, this.klaxon.waitDuration)

                this.physics.car.jump(false, 10)
                if (this.config.skateboard)
                {
                    this.sounds.play('carHorn2')
                }
                else
                {
                    this.sounds.play(Math.random() < 0.002 ? 'carHorn2' : 'aevHorn')
                }
                
            }

            // Rain horns
            if(_event.key === 'k' || _event.key === 'K')
            {
                const x = this.position.x + (Math.random() - 0.5) * 3
                const y = this.position.y + (Math.random() - 0.5) * 3
                const z = 6 + 2 * Math.random()

                this.objects.add({
                    base: this.resources.items.hornBase.scene,
                    collision: this.resources.items.hornCollision.scene,
                    offset: new THREE.Vector3(x, y, z),
                    rotation: new THREE.Euler(Math.random() * Math.PI * 2, Math.random() * Math.PI * 2, Math.random() * Math.PI * 2),
                    duplicated: true,
                    shadow: { sizeX: 1.5, sizeY: 1.5, offsetZ: - 0.15, alpha: 0.35 },
                    mass: 5,
                    soundName: 'horn',
                    sleep: false
                })
            }
        })
    }
}